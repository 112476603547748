import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthenticationService } from 'brain-data/service/auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthDashboardGuard {
  constructor(
    private oauthService: OAuthService,
    private authenticationService: AuthenticationService,
  ) {}

  canActivate() {
    if (!this.oauthService.hasValidAccessToken()) {
      this.handleInvalidToken();
      return false;
    }
    return true;
  }

  private handleInvalidToken(): void {
    this.authenticationService.logOut();
  }
}
