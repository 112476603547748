export enum CreateTokenOutcome {
  SUCCESS = 'SUCCESS',
  FAILURE_CANNOT_LOGIN = 'FAILURE_CANNOT_LOGIN',
  FAILURE_WRONG_CREDENTIALS = 'FAILURE_WRONG_CREDENTIALS',
  MFA_REQUIRED = 'MFA_REQUIRED',
}

export interface TokenDTO {
  outcome: CreateTokenOutcome;
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  mfaToken?: string;
  oobCode?: string;
  message?: string;
  bindingCode?: string;
}
