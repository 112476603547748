export const environment = {
  production: true,
  version: '1075226c90a5f694d52733dd5f71972d53103c94',
  googleMapsApiKey: 'AIzaSyAiv8XnKboepzWpLJW_dB6H4glm8WFDzTE',
  weatherApiKey: 'ecd5bb0efd934aea9a5122330232811',
  weatherApiUrl: 'https://api.weatherapi.com/v1',
  apiBaseUrl: '/api',
  apiPublicUrl: '/api/public',
  apiBaseAdminUrl: '/api/admin',
  apiAirQualityUrl: 'https://airquality.googleapis.com/v1',
};
