import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../../environments/environment';
import { of } from 'rxjs';
import { TokenDTO } from '@brain-auth/models/auth-token-dto.model';
import { STORAGE } from 'constants/storage.constant';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
import { getSignInUrl } from 'shared/services/utils/url/url.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService,
    private router: Router,
  ) {}

  login(username: string, password: string) {
    const body = {
      username,
      password,
    };

    return this.http.post<TokenDTO>(`${environment.apiPublicUrl}/oauth/token`, body);
  }

  loginMfa(tokenDto: TokenDTO) {
    const body = {
      mfaToken: tokenDto.mfaToken,
      oobCode: tokenDto.oobCode,
      bindingCode: tokenDto.bindingCode,
    };

    return this.http.post<TokenDTO>(`${environment.apiPublicUrl}/mfa/token`, body);
  }

  askForPasswordChange(email: string) {
    const body = {
      email,
    };

    return this.http.post<string>(`${environment.apiPublicUrl}/password-reset`, body);
  }

  saveStorageAndLoadUserProfile(tokenDto: TokenDTO) {
    localStorage.setItem(STORAGE.accessToken, tokenDto.accessToken ?? '');

    let idTokenClaims = null;

    if (tokenDto.idToken) {
      try {
        idTokenClaims = JSON.stringify(jwtDecode(tokenDto.idToken));
        localStorage.setItem('id_token_claims_obj', idTokenClaims);
      } catch (error) {
        console.error('Failed to decode idToken:', error);
      }
    }

    return of(idTokenClaims);
  }

  logOut() {
    this.oAuthService.logOut();
    void this.router.navigate([getSignInUrl()]);
  }

  userAuthenticated() {
    const rolesClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/roles';
    const tenantId = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/tenant_id';
    const claims = this.oAuthService.getIdentityClaims();
    if (claims && claims[rolesClaim] && claims[tenantId]) {
      const roles: string[] = claims[rolesClaim];
      const tenant: string[] = claims[tenantId];
      return {
        ...claims,
        roles,
        tenant,
      };
    }
    return null;
  }
}
