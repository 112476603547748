import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthenticationService } from 'brain-data/service/auth/authentication.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public constructor(
    private oAuthService: OAuthService,
    private authenticationService: AuthenticationService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (this.oAuthService.getAccessToken()) {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authenticationService.logOut();
            }
          }
        }
        return throwError(() => err);
      }),
    );
  }
}
