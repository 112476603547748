import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './reducers';
import { FacilityEffects } from './brain-data/store/effects/facility.effects';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { UtilityEffects } from 'brain-data/store/effects/utility.effects';
import { UtilityReportEffects } from './brain-data/store/effects/utility-report.effects';
import { UtilityContractsEffects } from 'brain-data/store/effects/utility-contract.effects';
import { AuthenticationEffects } from 'brain-data/store/effects/authentication.effects';
import { BearerInterceptor } from 'interceptors/bearer.interceptor';
import { HttpErrorInterceptor } from 'interceptors/http-error.interceptor';
import { UtilityBillsEffects } from 'brain-data/store/effects/utility-bills.effects';
import { ExternalProviderEffects } from 'brain-data/store/effects/external-provider.effects';
import * as Sentry from '@sentry/angular-ivy';
import { ErrorNotifitcationEffects } from 'brain-data/store/effects/error-notification.effects';
import { SubSystemEffects } from './brain-data/store/effects/sub-system.effects';
import { EmissionsEffects } from 'brain-data/store/effects/emissions.effects';
import { ReportEffects } from 'brain-data/store/effects/report.effects';
import { UIEffects } from '@brain-portal/store/ui.effects';
import { AlertEffects } from 'brain-data/store/effects/alert.effects';
import { UserEffects } from 'brain-data/store/effects/user.effects';
import { RoleEffects } from 'brain-data/store/effects/role.effects';
import { StartupService } from 'shared/services/utils/startup/startup.service';
import { ConfigEffects } from 'brain-data/store/effects/config.effects';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    OAuthModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      AlertEffects,
      ConfigEffects,
      EmissionsEffects,
      FacilityEffects,
      UtilityEffects,
      UtilityReportEffects,
      UtilityBillsEffects,
      UtilityContractsEffects,
      AuthenticationEffects,
      ExternalProviderEffects,
      SubSystemEffects,
      ErrorNotifitcationEffects,
      ReportEffects,
      UserEffects,
      RoleEffects,
      UIEffects,
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true }),
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BearerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (myStartupService: StartupService) => () => myStartupService.initializeApp(),
      deps: [StartupService],
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private oauthService: OAuthService) {
    this.oauthService.setStorage(localStorage);
    void this.oauthService.tryLogin();
  }
}
